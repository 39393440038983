import React, { useState } from 'react';

import { SxProps, Box, Popover, Typography } from '@mui/material';

import { ReactComponent as CopyIcon } from 'assets/images/tabler-icon/copy.svg';
import { ReactComponent as TickIcon } from 'assets/images/tabler-icon/tick.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { copyToClipboard } from 'utils/commonUtils';

import useStyle from './style';

interface ICopyButtonProps {
  label: string | number | JSX.Element;
  sx?: SxProps;
  copyText: string;
  noStyle?: boolean;
}

function CopyButton({ label, sx = {}, copyText, noStyle = false }: ICopyButtonProps): JSX.Element {
  const { classes } = useStyle();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    copyToClipboard(copyText);
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'copy-popover' : undefined;

  return (
    <Box className={`${noStyle ? classes.noStyle : classes.root} ${open ? 'active' : ''}`} sx={sx}>
      {label}
      <Box onClick={handleClick} sx={{ display: 'flex', alignItems: 'center' }}>
        <SVGIcon icon={CopyIcon} />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: -15,
        }}
      >
        <Typography
          sx={{
            p: '8px',
            background: '#3A3D47',
            borderRadius: '4px',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            height: '32px',
          }}
        >
          <SVGIcon icon={TickIcon} stroke='#fff' />
          Copied
        </Typography>
      </Popover>
    </Box>
  );
}
CopyButton.defaultProps = {
  sx: {},
  noStyle: false,
};
export default CopyButton;
