/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable import/prefer-default-export */
import { isAfter, isBefore } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import Lazy from 'yup/lib/Lazy';

import { IFormInputType } from 'common/components/form-builder/type';
import {
  IAdditionalFieldsList,
  IAdditionalFieldsType,
  IAddJobActivityFieldsList,
} from 'services/settings-service/settings.modal';

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  // eslint-disable-next-line func-names
  return this.test('test-phone-length', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    return isValidPhoneNumber(value ?? '') || createError({ path, message: errorMessage });
  });
});

export const email = yup
  .string()
  .email('validation.enter_valid')
  .required('validation.enter_valid');
export const positiveNumber = yup
  .number()
  .typeError('validation.enter_valid')
  .positive('validation.enter_valid')
  .min(1, 'validation.enter_valid')
  .required('validation.enter_valid')
  .nullable();
export const required = yup.string().required('validation.enter_valid');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phoneNumber = yup.string().phoneNumber('validation.enter_valid').nullable();
export const dateFuture = yup
  .date()
  .typeError('validation.enter_valid')
  .required('validation.enter_valid')
  .test('future_date_test', 'validation.future_date', (value) => {
    if (value) {
      const result = isAfter(value, new Date());
      return result;
    }
    return false;
  });
export const datePast = yup
  .date()
  .nullable()
  .test('future_date_test', 'validation.past_date', (value) => {
    if (value) {
      const result = isBefore(value, new Date());
      return result;
    }
    return true;
  });
export const arrayRequired = yup.array().min(1, 'validation.enter_valid');
export const objectRequired = yup.object().nullable().required('validation.enter_valid');
export const numberRequired = yup
  .number()
  .typeError('validation.valid_number')
  .required('validation.enter_valid');
export const numberTypeCheck = yup.number().typeError('validation.valid_number');

export const numberRequiredDropdown = yup.number().nullable().required('validation.enter_valid');
export const noSpecialChar = yup
  .string()
  .required('validation.enter_valid')
  .matches(/^[a-zA-Z0-9 ]*$/, 'enter name without special char');

export const addressValidation = yup.object().shape({
  latitude: numberRequired,
  longitude: numberRequired,
});

export const additionalFieldsLazyValidation = (
  extraFields: IAdditionalFieldsList[],
): Lazy<yup.AnySchema> => {
  return yup.lazy(() => {
    const shapes: Record<string, yup.AnySchema> = {};
    extraFields.forEach(({ name, required: requiredStatus, type }) => {
      if (requiredStatus) {
        if (type === IAdditionalFieldsType.multi_select) {
          shapes[name] = arrayRequired.required('validation.enter_valid');
        } else if (type === IAdditionalFieldsType.number) {
          shapes[name] = numberRequired;
        } else if (type === IAdditionalFieldsType.attachment) {
          shapes[name] = yup
            .array()
            .transform((_, originalValue) => {
              return originalValue === undefined ? [] : originalValue;
            })
            .min(1, 'validation.enter_valid')
            .required('validation.enter_valid');
        } else {
          shapes[name] = required;
        }
      } else if (type === IAdditionalFieldsType.number) {
        shapes[name] = yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : Number(String(originalValue).replace(/,/g, ''));
          })
          .typeError('validation.valid_number')
          .nullable();
      }
    });
    return yup.object().shape(shapes);
  });
};

export const quantityValidator = yup
  .number()
  .typeError('Quantity must be number')
  .min(1, 'Quantity should not be less than 1')
  .max(100, 'Quantity should not be greater than 100')
  .required('Quantity is required');

const partSchema = yup.object({
  quantity: quantityValidator,
});

export const partArraySchema = yup.array().of(partSchema).required('Parts array is required');

export const itemSchema = yup
  .object({
    parts: partArraySchema,
  })
  .nullable();

const dynamicPartSchema = yup
  .object()
  .test(
    'is-not-empty',
    'Root object should not be empty',
    (value) => value && Object.keys(value).length > 0,
  )
  .test('validate-dynamic-keys', function (value) {
    if (!value || typeof value !== 'object')
      return this.createError({ message: 'Invalid root object' });

    const errors: Record<string, unknown> = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(value)) {
      try {
        itemSchema.validateSync(value[key], { abortEarly: false }); // Validate item
      } catch (err: unknown) {
        const yupError = err as unknown as ValidationError;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errors[key] = err?.inner?.map(
          (e: { path: string; message: string; value: string }, index: number) => {
            return {
              path: e?.path,
              message: e?.message,
              partId: yupError.value.parts[index]?.partId,
            };
          },
        ); // Store detailed errors
      }
    }

    return Object.keys(errors).length > 0
      ? this.createError({ message: JSON.stringify(errors, null, 2) })
      : true;
  });

export const dynamicFieldsLazyValidation = (
  extraFields: IAddJobActivityFieldsList[],
): Lazy<yup.AnySchema> => {
  return yup.lazy(() => {
    const shapes: Record<string, yup.AnySchema> = {};
    extraFields.forEach(({ fieldId, required: requiredStatus, inputType }) => {
      if (requiredStatus) {
        if (inputType === IFormInputType.MultiSelect || inputType === IFormInputType.Checkbox) {
          shapes[fieldId] = arrayRequired;
        } else if (inputType === IFormInputType.NumberInput) {
          shapes[fieldId] = numberRequired;
        } else if (inputType === IFormInputType.Date || inputType === IFormInputType.DateTime) {
          shapes[fieldId] = required.nullable();
        } else if (inputType === IFormInputType.Attachment) {
          shapes[fieldId] = yup
            .array()
            .transform((_, originalValue) => (!originalValue ? [] : originalValue))
            .min(1, 'validation.enter_valid');
        } else if (inputType === IFormInputType.Parts) {
          shapes[fieldId] = dynamicPartSchema;
        } else {
          shapes[fieldId] = required.nullable();
        }
      } else if (inputType === IFormInputType.NumberInput) {
        shapes[fieldId] = yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : Number(String(originalValue).replace(/,/g, ''));
          })
          .typeError('validation.valid_number')
          .nullable();
      }
    });
    return yup.object().shape(shapes);
  });
};
