/* eslint-disable import/no-cycle */
import { IListApiResponse } from 'services/common/common.modal';
import {
  IAdditionalFieldsEntity,
  IAdditionalFieldsList,
} from 'services/settings-service/settings.modal';
import { addUrlId, dirtyValues, IDirtyFields } from 'utils/commonUtils';

import { APIS } from '../../constants/api.constants';
import { BaseApi } from '../common/base-api';
import { processResponse } from '../common/common';
import { INewUserDetails, IPinnedFields } from './user.modal';

const getLoggedInUserDetails = async (): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.get(APIS.SETTINGS.ME);

  const details = processResponse<INewUserDetails>(response);

  return details;
};

async function fetchModuleAdditionalFieldsData(
  module: string,
  page: number,
): Promise<IListApiResponse<IAdditionalFieldsList[]> | Error> {
  try {
    const response = await BaseApi.get(APIS.SETTINGS.GET_ADDITIONAL_FIELDS, {
      params: {
        entity: module,
        page,
        size: 50,
      },
    });
    return processResponse<IListApiResponse<IAdditionalFieldsList[]>>(response);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching additional fields', error);
    throw error;
  }
}

async function fetchAdditionalFieldAllPages(
  module: string,
  totalPages: number,
): Promise<IListApiResponse<IAdditionalFieldsList[]>[]> {
  const pagePromises: Promise<IListApiResponse<IAdditionalFieldsList[]>>[] = [];

  for (let page = 2; page <= totalPages; page++) {
    pagePromises.push(
      fetchModuleAdditionalFieldsData(module, page) as unknown as Promise<
        IListApiResponse<IAdditionalFieldsList[]>
      >,
    );
  }

  const allPagesData = await Promise.all(pagePromises);
  return allPagesData.flat();
}

async function fetchAllModuleAdditionalFieldsApis(
  modules: string[],
): Promise<{ [x: string]: IAdditionalFieldsList[] }[]> {
  const apiPageDetails = await Promise.all(
    modules.map(async (module) => {
      const data = (await fetchModuleAdditionalFieldsData(module, 1)) as IListApiResponse<
        IAdditionalFieldsList[]
      >;
      const totalPages = Math.ceil((data.meta.count ?? 1) / 50) || 1;
      if (totalPages > 1) {
        const allPagesData = await fetchAdditionalFieldAllPages(module, totalPages);
        return {
          [module]: [...data.data, ...allPagesData.map((pageFields) => pageFields.data).flat()],
        };
      }
      return { [module]: data.data };
    }),
  );

  const combinedData = apiPageDetails.flat();
  return combinedData;
}

const getAdditionalFields = async (
  modulesParams?: string[],
): Promise<Error | { [x: string]: IAdditionalFieldsList[] }> => {
  return new Promise((resolve, reject) => {
    const modules = modulesParams ?? Object.values(IAdditionalFieldsEntity);
    fetchAllModuleAdditionalFieldsApis(modules)
      .then((data) => {
        const finalData = data.reduce((acc, d) => {
          return {
            ...acc,
            ...d,
          };
        }, {});
        resolve(finalData);
      })
      .catch(() => {
        reject();
      });
  });
};

const updatePinnedFields = async (data: IPinnedFields): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.patch(`${APIS.SETTINGS.ME}`, {
    pinnedFields: data,
  });
  return processResponse<INewUserDetails>(response);
};

const updateUserDetails = async (
  payload: unknown,
  dirtyFields: IDirtyFields,
  isPatch: boolean,
): Promise<Error | INewUserDetails> => {
  const data = isPatch
    ? dirtyValues(dirtyFields, payload as Record<string, string>)
    : (payload as Record<string, string>);

  const response = await BaseApi[isPatch ? 'patch' : 'post'](`${APIS.SETTINGS.ME}`, {
    ...data,
  });
  return processResponse<INewUserDetails>(response);
};

const updateUserInvite = async (
  orgId: string,
  payload: { action: string },
): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.post(`${APIS.SETTINGS.UPDATE_INVITE}/${orgId}/${payload.action}`);
  return processResponse<INewUserDetails>(response);
};

const resentUserInvite = async (id: string | number): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.post(addUrlId(APIS.SETTINGS.RESEND_USER, id));
  return processResponse<INewUserDetails>(response);
};

const revokeUserInvite = async (id: string | number): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.post(addUrlId(APIS.SETTINGS.REVOKE_INVITE, id));
  return processResponse<INewUserDetails>(response);
};
export {
  getLoggedInUserDetails,
  updatePinnedFields,
  updateUserDetails,
  updateUserInvite,
  getAdditionalFields,
  resentUserInvite,
  revokeUserInvite,
};
