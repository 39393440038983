/* eslint-disable react/require-default-props */
import React from 'react';

import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Box,
  Stack,
  Typography,
} from '@mui/material';

import { ReactComponent as InfoIcon } from 'assets/images/tabler-icon/info.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { remCalc } from 'theme/utils';

import CopyButton from '../copy-button/CopyButton';

interface ITooltipProps {
  message: string;
  children?: JSX.Element;
  copy?: boolean;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '12px',
    fontSize: remCalc(14),
  },
}));

function CustomTooltip({ message, children, copy }: ITooltipProps): JSX.Element | null {
  if (!message) {
    return null;
  }

  const title = copy ? (
    <Stack>
      <Typography
        sx={{
          fontSize: remCalc(14),
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        {message}
        <CopyButton label='' copyText={message} noStyle />
      </Typography>
    </Stack>
  ) : (
    message
  );

  return (
    <BootstrapTooltip
      title={title}
      placement='right'
      sx={{
        '& .MuiTooltip-tooltip': {
          p: '12px 16px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children ?? <SVGIcon icon={InfoIcon} />}
      </Box>
    </BootstrapTooltip>
  );
}

export default CustomTooltip;
