import { makeStyles } from 'tss-react/mui';

import { remCalc } from 'theme/utils';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    borderRadius: '4px',
    background: '#F5F5F5',
    fontSize: remCalc(14),
    fontWeight: 400,
    overflow: 'hidden',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #F5F5F5',
      background: '#F5F5F5',
      borderRadius: '4px',
    },
    '&.active': {
      background: '#F5F5F5',
      border: '1px solid #F5F5F5',
    },
  },
  noStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
}));

export default useStyles;
