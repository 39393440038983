import { NestedValue } from 'react-hook-form';

// eslint-disable-next-line import/no-cycle
import { IModuleValue } from 'common/components/multi-module-dropdown/MultiModuleDropdown';
import { IAdditionFieldsPayload, IListApiResponse } from 'services/common/common.modal';

export interface IAddress {
  line1: string;
  line2: string;
  locality: string;
  postcode: string | null | number;
  region: string;
  country: string;
  latitude: number | string | null;
  longitude: number | string | null;
}

export interface ILocationList extends Omit<IAddLocation, 'organisationId'> {
  locationId: string;
  created: Date;
  organisationId: string;
}
export interface IUserList extends IAddUser {
  userId: string;
}
export interface IAddOrganisation {
  name: string;
  address: IAddress;
  prefix: string;
}

export interface IAddLocationPayload extends Partial<IAdditionFieldsPayload> {
  name: string;
  organisationId: string;
  address: IAddress;
  organisationLocationGroupIds: NestedValue<IOptions>[];
}

export interface ILocationDetails extends IAddLocation, Partial<IAdditionFieldsPayload> {
  locationId: string;
  organisation: { id: string; name: string };
  locationGroups: { id: string; name: string }[];
}

export interface ILocationGroupDetails {
  locationGroupId: string;
  name: string;
  organisation: { id: string; name: string };
}

export interface IOrganisationList extends IAddOrganisation {
  organisationId: string;
}

export type IOrganisationListResponse = IListApiResponse<IOrganisationList[]>;

export interface IJobTypeConfig {
  iconType: string;
  name: string;
}

export interface ICategoriesPayload {
  name: string;
  organisationId: NestedValue<IOptions> | null;
}

export interface IJobTypeConfigDetails extends IJobTypeConfig {
  organisation: {
    name: string;
    id: string;
  };
  jobTypeId: string;
  customType: boolean;
}

export interface ICategoriesDetails extends ICategoriesPayload {
  organisation: {
    name: string;
    id: string;
  };
  categoryId: string;
}

export interface ICategoryPartList {
  id: string;
  name: string;
  parts: {
    count: number;
  };
}

export interface IOptions {
  label: string;
  value: string;
}

export interface IAddUser {
  organisation: NestedValue<IOptions> | null;
  email: string;
  name: string;
  preferredName: string;
  location: NestedValue<IOptions[]>;
  role: NestedValue<IOptions[]>;
}

export enum UserType {
  operator = 'operator',
  user = 'user',
}

export enum ActionType {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  link = 'link',
  action = 'action',
  operate = 'operate',
  upload = 'upload',
  manage = 'manage',
}

export enum ModulesType {
  organisations = 'organisations',
  organisation_locations = 'organisation_locations',
  users = 'users',
  assets = 'assets',
  devices = 'devices',
  jobs = 'jobs',
  parts = 'parts',
  overview = 'overview',
  images = 'images',
  assignments = 'assignments',
  additional_fields = 'additional_fields',
  settings = 'settings',
  reports = 'reports',
  api_keys = 'api_keys',
  feed = 'feed',
}

export interface IAddUserPayload extends Partial<IAdditionFieldsPayload> {
  preferredName: string;
  name: string;
  email: string;
  phone: string | null;
  userType: UserType | null;
  permissions: string[];
  organisationLocationIds: NestedValue<IOptions[]>;
  organisationLocationGroupIds: NestedValue<IOptions[]>;
  action?: string;
}

export interface IUserDetailsResponse extends IAddUserPayload {
  userId: string;
  organisation: {
    name: string;
    id: string;
  };
  organisationLocations: {
    name: string;
    id: string;
  }[];
  organisationLocationGroups: {
    name: string;
    id: string;
    count: number;
  }[];
}

export interface IPendingInvites {
  archived: string;
  updated: string;
  created: string;
  status: string;
  email: string;
  sender: {
    id: string;
    name: string;
  };
  id: string;
  userId: string;
  organisation: {
    id: string;
    name: string;
  };
}

export interface IAddUserResponse {
  id: string;
}

export interface IUserTaskDetails {
  inspect: number;
  qualityControl: number;
  repairs: number;
  bounce: number;
  transfer: number;
  recoveries: number;
  id: string;
}

export interface IAddLocation {
  organisationId: NestedValue<IOptions> | null;
  name: string;
  address: IAddress;
}

export interface IOrganisationDetails {
  created: Date;
  address: IAddress;
  email: string;
  name: string;
  prefix: string;
  organisationId: string;
  locations: {
    id: string;
    name: string;
  }[];
  totalAssets: number;
  totalUsers: number;
}

export enum IAdditionalFieldsEntity {
  asset = 'asset',
  job = 'job',
  part = 'part',
  organisation_location = 'organisationLocation',
  user = 'user',
}

export enum IAdditionalFieldsType {
  text = 'text',
  select = 'select',
  date = 'date',
  date_time = 'datetime',
  number = 'number',
  multi_select = 'select-multiple',
  attachment = 'attachment',
}

export interface IAdditionalFields {
  entity: IAdditionalFieldsEntity | null;
  type: IAdditionalFieldsType | null;
  name: string;
  description: string;
  required: boolean;
  options?: string[] | null;
}

export interface IAdditionalFieldsList extends IAdditionalFields {
  fieldId: string;
  organisation: {
    name: string;
    id: string;
  };
}

export enum IAssetConfigModule {
  types = 'types',
  makes = 'makes',
  models = 'models',
}

export interface IAssetConfigCommonPayload {
  name: string;
  makeId: NestedValue<IOptions> | null;
}

export interface IAssetMakesDetails extends Omit<IAssetConfigCommonPayload, 'makeId'> {
  organisation: {
    id: string;
    name: string;
  };
  makeId: string;
}

export interface IAssetTypeDetails extends Omit<IAssetConfigCommonPayload, 'makeId'> {
  organisation: {
    id: string;
    name: string;
  };
  typeId: string;
}

export interface IAssetModelDetails extends Omit<IAssetConfigCommonPayload, 'makeId'> {
  organisation: {
    id: string;
    name: string;
  };
  make: {
    id: string;
    name: string;
  };
  modelId: string;
}

export type IAssetConfigDetails = IAssetMakesDetails | IAssetTypeDetails | IAssetModelDetails;

export interface IGroupsPayload {
  name: string;
  locationIds: NestedValue<IOptions>[];
}

export interface IGroupsDetails {
  organisation: {
    id: string;
    name: string;
  };
  count: number;
  name: string;
  locations: {
    id: string;
    name: string;
  }[];
  locationGroupId: string;
}

export interface IKeyPayload {
  name: string;
  description: string;
  permissions: string[];
  expiry: string;
}

export interface IConnectionPayload {
  providerIdKey: string;
  type: string;
}

export interface IApiKeysDetails {
  expiry: string;
  lastUsed: string;
  permissions: string[];
  description: string;
  name: string;
  suffix: string;
  id: string;
  created: string;
}

export interface IConnectionDetails {
  organisation: {
    name: string;
    id: string;
  };
  archived: string;
  updated: string;
  created: string;
  providerIdKey: string;
  username: string;
  authType: string;
  type: string;
  id: string;
}

export interface IRecordHistory {
  created: string;
  user: {
    email: string;
    name: string;
    id: string;
  };
  toData: unknown;
  fromData: unknown;
  eventType: string;
}

export interface IAddJobActivity {
  name: string;
  iconType: string;
  description: string;
  jobTypeIds: IModuleValue[];
}

export interface IAddJobActivityFieldsPayload {
  validation: Record<string, unknown>;
  options?: string[];
  required: boolean;
  defaultValue: string | number;
  toolTip: string;
  hint: string;
  placeHolder: string;
  inputType: string;
  label: string;
}

export interface IAddJobActivityPayload {
  name: string;
  iconType: string;
  description: string;
  jobTypeIds: IModuleValue[];
  fields: IAddJobActivityFieldsPayload;
}

export interface IAddJobActivityFieldsList extends IAddJobActivityFieldsPayload {
  fieldId: string;
}

export interface IAddJobActivityList extends Omit<IAddJobActivityPayload, 'fields'> {
  jobActivityId: string;
  jobTypes: {
    name: string;
    id: string;
  }[];
  fields: IAddJobActivityFieldsList[];
  customActivity: boolean;
}
